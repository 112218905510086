import {
    IonAvatar, IonBadge, IonButton, IonButtons, IonCardSubtitle, IonCol, IonContent,
    IonFooter, IonHeader, IonIcon, IonImg, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel, IonList, IonNote, IonPage, IonRadioGroup, IonRadio,
    IonRow, IonTitle, IonToolbar, useIonToast, IonInput, IonSelect, IonSelectOption,
    IonGrid,
    IonItemDivider,
    IonCard,
    IonProgressBar,
    IonCardContent, IonTextarea, IonSegment, IonSegmentButton
} from "@ionic/react";
import { cart, cartOutline, checkmarkSharp, chevronBackOutline, trashOutline, alertSharp,
    add, remove
 } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { CartStore } from "../data/CartStore";
import { ProductStore } from "../data/ProductStore";

import styles from "./CartProducts.module.css";
import Product from "./Product";
import { update } from "pullstate";

import { coreLocations } from '../data';
import { set } from "date-fns";

import { UserDetailsForm } from "./Checkout";
import PaymentForm from '../components/PaymentGateway';
import { encodeId, decodeId } from "../utils/encoding";

const BuyNow = () => {

    const history = useHistory();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categorySlug = queryParams.get('category');
    const encodedId = queryParams.get('product_id');
    const product_id = decodeId(encodedId);

    const cartRef = useRef();
    const shopCart = CartStore.useState(s => s.product_ids);

    const products = ProductStore.useState(s => s.products);
    const productsFullyLoaded = ProductStore.useState(s => s.fullyLoaded);
    const [product, setProduct] = useState({});

    const [category, setCategory] = useState({});
    const [images, setImages] = useState([]);

    const [presentToast] = useIonToast();

    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [buyerNote, setBuyerNote] = useState('');
    const [address, setAddress] = useState('');

    const incrementQuantity = () => {
        setQuantity((prevQuantity) => Math.min(prevQuantity + 1, product.stock));
    };

    const decrementQuantity = () => {
        setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
    };

    const [deliveryMode, setDeliveryMode] = useState('pickup');
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [selectedPaidDel, setSelectedPaidDel] = useState(null);
    const [delPrice, setDelPrice] = useState(0);
    const [delDays, setDelDays] = useState(0);

    const [ payLoadData, setPayLoadData ] = useState(
        {
            address: "",
            categorySlug: "",
            data: {},
            dzo: "",
            newDeliveryLocID: null,
            quantity: quantity,
            price: 0,
            productID:null,
            note: ''
        }
    );

    useEffect(() => {

        if (!productsFullyLoaded)
            return;

        if (products.length < 1)
            return;

        const tempCategory = products.filter(p => p.slug === categorySlug)[0];

        if (!tempCategory)
            return;

        const tempProduct = tempCategory.products.filter(p => parseInt(p.id) === parseInt(product_id))[0];

        if (!tempProduct)
            return;

        setCategory(tempCategory);
        setProduct(tempProduct);
        setImages(tempProduct.images);
        setTotal((parseInt(tempProduct.price) * quantity) + delPrice);

        setPayLoadData({
            ...payLoadData,
            categorySlug: categorySlug,
            productID: product_id,
            quantity: quantity,
            note: buyerNote
        });

    }, [product_id, productsFullyLoaded, quantity, buyerNote]);

    const handleFreeLocations = (index, location) => {
        setSelectedPickup(location);
        setSelectedPaidDel(null);
        setDelPrice(0);
        setTotal(parseInt(product.price) * quantity);
        setAddress('');
        setPayLoadData({
            ...payLoadData,
            newDeliveryLocID: location
        });
    };

    const handleAddress = (index, addr) => {
        setPayLoadData({
            ...payLoadData,
            address: addr
        });
        setAddress(addr);
    };
    const handlePaidLocations = (index, dzongkhag, delLocID, price, data) => {
        setSelectedPickup(null);
        setSelectedPaidDel(dzongkhag);
        setDelPrice(price);

        setDelDays(data?.days);

        //setTotal(prevTotal => prevTotal + (parseInt(product.price.replace("£", "")) * quantity));
        let totalPrice = (parseInt(product.price) * quantity) + parseInt(price);
        price && setTotal(totalPrice);
        setPayLoadData({
            ...payLoadData,
            newDeliveryLocID: delLocID,
            price: price,
            dzo: dzongkhag,
            data: data
        });
    }

    // Function to process delivery locations and return select options
    function getSelectOptions(deliveryLocations) {
        return deliveryLocations.filter(delivery_loc => delivery_loc.charges > 0).flatMap(dzo => {
            const locationsArray = JSON.parse(dzo.location); // Parse the JSON string to an array
            return locationsArray.map((coreLocKey, clIdx) => {
                const label = Object.entries(coreLocations).find(([key, value]) => key === coreLocKey)?.[1];
                return {
                    key: `option-${dzo.id}-${clIdx}`,
                    value: coreLocKey,
                    label: label,
                    delLocID: dzo.id,
                    price: dzo.charges,
                    data: JSON.parse(dzo.data)
                };
            });
        });
    }

    const [step, setStep] = useState(1);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const getProgressValue = () => {
        return step === 1 ? 0.5 : 1;
    };

    const [ purchaserData, setPurchaserData ] = useState({
        isExistingUser: false,
        isUserFormValid: false,
        userData: {}
    });

    const onCompletion = () => {
        history.push('/my-purchases');
    }

    return (

        <IonPage id="category-page" className={styles.categoryPage}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" routerLink="/category/" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Back
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Cart</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>

                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" routerLink={`/category/${categorySlug}/${encodedId}`} routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Back
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Buy Now</IonTitle>

                    <IonButtons slot="end" class="ion-padding-left">
                        <IonButton color="drukre" routerLink="/cart">
                            {shopCart.length}
                            <IonIcon ref={cartRef} className="animate__animated" icon={cartOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonList>
                    <IonItem>
                        <IonGrid fixed={true}>
                            <IonRow>
                                <IonCol size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                    <IonItem lines="none" detail={false} className={styles.cartItem}>
                                        <IonAvatar>
                                            <IonImg src={`${process.env.REACT_APP_PRODUCT_ASSETS}/${images[0]}`} />
                                        </IonAvatar>
                                        <IonLabel className="ion-padding-start ion-text-wrap">
                                            <p>{category?.name}</p>
                                            <h4>{product.name}</h4>
                                        </IonLabel>

                                        <div className={styles.cartActions}>
                                            <IonBadge color="danger">Nu. {product.price}</IonBadge>
                                        </div>
                                    </IonItem>
                                </IonCol>

                                <IonCol size-xl="2" size-lg="2" size-md="2" size-sm="12" size-xs="12">
                                    <IonNote>Quantity</IonNote>
                                    <IonInput labelPlacement="stacked" value={quantity} 
                                        onIonInput={(e) => setQuantity(Math.max(Number(e.target.value), 1))} 
                                        className="ion-text-center">
                                        <IonButton slot="start" aria-label="Subtract" onClick={decrementQuantity}>
                                            <IonIcon icon={remove}></IonIcon>
                                        </IonButton>
                                        <IonButton slot="end" aria-label="Add" onClick={incrementQuantity}>
                                            <IonIcon icon={add}></IonIcon>
                                        </IonButton>
                                    </IonInput>
                                </IonCol>

                                <IonCol size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                    {(product.delivery_locations && product.delivery_locations.length > 0) ?
                                        <>
                                            <IonSegment onIonChange={e => {setDeliveryMode(String(e.detail.value) || 'pickup'); setDelPrice(0); setTotal(parseInt(product.price) * quantity); setAddress(''); setSelectedPickup(null); setSelectedPaidDel(null);}}>
                                                <IonSegmentButton value="pickup">
                                                    <IonLabel>Free Pickup</IonLabel>
                                                </IonSegmentButton>
                                                {product.delivery_locations.some(delivery_loc => delivery_loc.charges > 0) && (
                                                <IonSegmentButton value="delivery">
                                                    <IonLabel>Delivery</IonLabel>
                                                </IonSegmentButton>
                                                )}
                                            </IonSegment>
                                            {deliveryMode === 'pickup' && product.delivery_locations.some(delivery_loc => delivery_loc.charges < 1) && (
                                                <div className="ion-padding">
                                                    <IonNote className="ion-padding-start">Free Pick Up Location</IonNote>
                                                    <IonRadioGroup labelPlacement="floating" fill="outline" value={selectedPickup} onIonChange={e => handleFreeLocations(product_id, e.detail.value)}>
                                                        {product.delivery_locations.filter(delivery_loc => delivery_loc.charges < 1).map((delivery_loc, ii) => (
                                                            <IonItem className="ion-padding-start" key={`item-${ii}`}>
                                                                <IonLabel>{delivery_loc.location} <IonNote> {JSON.parse(delivery_loc.data)?.days ? ` - Pickup ready within ${JSON.parse(delivery_loc.data)?.days} day(s)` : null}</IonNote></IonLabel>
                                                                <IonRadio slot="end" value={delivery_loc.id} />
                                                            </IonItem>
                                                        ))}
                                                    </IonRadioGroup>
                                                </div>
                                            )}
                                            {deliveryMode === 'delivery' && product.delivery_locations.some(delivery_loc => delivery_loc.charges > 0) && (
                                                <div className="ion-padding">
                                                    <IonNote className="ion-padding-start">Delivery on Extra Cost</IonNote>
                                                    <IonRow>
                                                        <IonCol size="12" className="ion-padding-start">
                                                            <IonSelect
                                                                value={selectedPaidDel} onIonChange={e => {
                                                                    const selectedOption = getSelectOptions(product.delivery_locations).find(option => option.value === e.detail.value);
                                                                    if (selectedOption) {
                                                                        handlePaidLocations(product_id, e.detail.value, selectedOption.delLocID, selectedOption.price, selectedOption.data);
                                                                    } else {
                                                                        setSelectedPaidDel(null);
                                                                        setDelPrice(0);
                                                                        setTotal(parseInt(product.price) * quantity);
                                                                    }
                                                                }}
                                                                labelPlacement="floating" fill="outline"
                                                                placeholder="Select Location"
                                                            >
                                                                <IonSelectOption value={null}>Select Locality</IonSelectOption>
                                                                {getSelectOptions(product.delivery_locations).map(({ key, value, label, delLocID, price, data }) => (
                                                                    <IonSelectOption key={key} value={value}>
                                                                        {label}
                                                                    </IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        </IonCol>
                                                        <IonCol size="12" className="ion-padding-start">
                                                            <IonInput value={address}
                                                                placeholder="Enter address here" type="text"
                                                                disabled={selectedPaidDel == null} 
                                                                onIonInput={e => {
                                                                    // You need to have delLocID and price available here, possibly from component state
                                                                    handleAddress(product_id, e.detail.value);
                                                                }}
                                                                labelPlacement="floating" fill="outline"
                                                                label="Enter Your Address">
                                                            </IonInput>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="6" className="ion-padding-start">
                                                            {delDays ? <>Delivery Time : <IonNote>Within {delDays} day(s)</IonNote></> : null}
                                                        </IonCol>
                                                        <IonCol size="6">
                                                            {delPrice ?
                                                                <>
                                                                    Delivery Charge &nbsp;<IonBadge className="ion-float-right" color="danger">Nu. {delPrice}</IonBadge>
                                                                </>
                                                                : null}
                                                        </IonCol>
                                                    </IonRow>
                                                </div>
                                            )}
                                        </>
                                        : null}
                                </IonCol>
                                <IonCol size-md="8" offset-md="2">
                                    <IonNote className="ion-padding-start">Note to Seller</IonNote>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonTextarea label="Note to Seller" labelPlacement="floating" fill="outline" 
                                                placeholder="A note can be anything such as product specs, gifting note, etc..."
                                                value={buyerNote} onIonInput={(e) => setBuyerNote(e.target.value)}
                                                counter={true}
                                                maxlength={200}
                                                autoGrow={true}
                                                counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
                                            ></IonTextarea>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                </IonList>

                {/* Checkout Form */
                selectedPaidDel || selectedPickup ?
                    
                    <IonRow>
                        <IonCol size="12">
                            <IonRow>
                                <IonCol className="ion-align-self-center" size-md="6" offset-md="3">
                                    <br></br>
                                    <IonProgressBar color="drukre" value={getProgressValue()}></IonProgressBar>
                                    {step === 1 && <UserDetailsForm purchaserData={purchaserData} setPurchaserData={setPurchaserData} onNext={handleNextStep} />}
                                    {step === 2 && <PaymentForm purchaserData={purchaserData} payLoad={[payLoadData]} 
                                        api= '/api/orders' total={total} onSuccess={onCompletion} onPrevious={handlePreviousStep} />}
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol size="12">
                                    <div className="step-navigation">
                                        {step > 1 && (
                                            <IonButton color="medium" className="ion-padding" onClick={handlePreviousStep}>
                                                Back
                                            </IonButton>
                                        )}
                                        {step < 2 && (
                                            <IonButton color="drukre" disabled={!purchaserData.isUserFormValid || (selectedPaidDel == null && selectedPickup == null)} 
                                                className="ion-float-right ion-padding" onClick={handleNextStep}
                                            >
                                                Go to Payment
                                            </IonButton>
                                        )}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                : null}
            </IonContent>

            <IonFooter>
                <div className={styles.cartCheckout}>
                    <IonCardSubtitle>Nu. {total.toFixed(2)}</IonCardSubtitle>
                </div>
            </IonFooter>
        </IonPage>
    );
}

export default BuyNow;