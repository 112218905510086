declare const grecaptcha: any;

export function getCaptchaToken(action: any) {
    return new Promise((res, rej) => {
        grecaptcha.enterprise.ready(() => {
            grecaptcha.enterprise.execute('6LdlNdIqAAAAAP253gGSIUvdnsv7WDk2PPXvYlmV', {action: action}).then((token: any) => {
                return res(token);
            })
        })
    })
}