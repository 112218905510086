import React, { useState, useEffect } from 'react';
import {
    IonBadge, IonButton, IonIcon,
    IonModal, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonContent,
    IonText,
    IonRow,
    IonCol,
    useIonLoading,
    useIonToast,
    IonNote,
    IonInput,
    useIonModal,
    IonPage,
    IonSegmentButton,
    IonSegment
} from '@ionic/react';
import { notificationsOutline, close, closeOutline, chatbubblesOutline, checkmarkSharp, copyOutline, copySharp } from 'ionicons/icons';

import { Clipboard } from '@capacitor/clipboard';

import { format } from 'date-fns';

import './Notification.css';

import axiosCall from '../data/axios';

import { getCaptchaToken } from "../Helper";

import AuthContext from "../UserContext";

import { RMAPaymentGateway } from './PaymentGateway';

const formatTimestamp = (timestamp: any) => {
    return format(new Date(timestamp), 'PPpp'); // PPpp gives a readable format like June 6, 2024 at 11:37 AM
};

const WalletModal = ({ walletBalance, onDismiss, afterEvent }: { walletBalance: number, onDismiss: any, afterEvent: () => void }) => {

    const { authValues, fetchUser, logout } = React.useContext(AuthContext);

    const [error, setError] = useState('');

    const [spinnerPresent, spinnerDismiss] = useIonLoading();
    const [presentToast] = useIonToast();

    const [ paymentMode, setPaymentMode ] = useState<string>('rma');

    const [stagedAmount, setStagedAmount] = useState(0);
    const [stagedRef, setStagedRef] = useState('');

    const [ purchaserData, setPurchaserData ] = useState({
        isExistingUser: false,
        isUserFormValid: true,
        userData: {}
    });

    const addFundToWallet = async (event: any) => {
            
        if (stagedAmount < 1) {
            setError("Please enter a valid amount to add.");
            return;
        }

        if (paymentMode == 'eft' && !stagedRef) {
            setError("Please enter a reference or description identifiable for confirmation.");
            return;
        }

        const otpData = {
            code: '',
            rmaAccountNo: '',
            rmaBank: '',
            bfsTransactionId: ''
        };

        if(paymentMode == 'rma') {
            otpData.code = event.detail.data.otpCode;
            otpData.rmaAccountNo = event.detail.rmaAccountNo;
            otpData.rmaBank = event.detail.rmaBank;
            otpData.bfsTransactionId = event.detail.bfs_bfsTxnId;
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        var captchaData = {
            captchaToken: '',
            captchaAction: ''
        };

        await getCaptchaToken('PAYMENT').then((token: any) => { // Here wait token generated
            if (token) {
                captchaData.captchaToken = token;
                captchaData.captchaAction = 'PAYMENT';
            }
        });

        spinnerPresent({
            message: 'Processing. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });
        
        axiosCall("/api/wallet", 'post',
            {
                //cardObject: cardObject,
                paymentMethod: paymentMode,
                otpData: otpData,
                //payLoad: payLoad, 
                amount: stagedAmount,
                description: stagedRef,
                type: 'credit',
                captchaData: captchaData
            }, true
        ).then((res: any) => {
            console.log(res);
            if (res.data.status !== 1)
                setError('Fund submission unsuccessful! ' + res.message);
            else {
                presentToast({
                    message: 'Fund submission successfully! You will be notified when it becomes available in your wallet.',
                    duration: 3000,
                    color: 'success'
                });
            }
        })
        .catch((error) => {
            setError("Fund submission unsuccessful! Please try again.");
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });
    };

    const copyToClipboard = async (text: string): Promise<void> => {
        try {
            await Clipboard.write({
                string: text
            });
            presentToast({
                message: 'Account number copied to clipboard.',
                duration: 3000,
                color: 'success'
            });
        } catch (err) {
            console.error('Failed to copy text:', err);
            presentToast({
                message: 'Failed to copy text to clipboard.',
                duration: 3000,
                color: 'danger'
            });
        }
        /*
        if (!navigator.clipboard) {
            console.error('Clipboard functionality is not available.');
            // Optionally, present a toast message to inform the user
            presentToast({
                message: 'Clipboard functionality is not available in your browser.',
                duration: 3000,
                color: 'danger'
            });
            return;
        }
    
        navigator.clipboard.writeText(text).then(() => {
            presentToast({
                message: 'Account number copied to clipboard.',
                duration: 3000,
                color: 'success'
            });
        }).catch(err => {
            console.error('Failed to copy text: ', err);
            // Optionally, present a toast message to inform the user of the error
            presentToast({
                message: 'Failed to copy text to clipboard.',
                duration: 3000,
                color: 'danger'
            });
        });
        */
    };

    return (
        <IonPage id="wallet-modal">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Add fund to your Wallet</IonTitle>
                    <div slot="end" className="ion-padding" onClick={() => onDismiss()}><IonIcon size="small" color="drukre" icon={closeOutline} /></div>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonSegment onIonChange={e => setPaymentMode(String(e.detail.value) || 'rma')}>
                            <IonSegmentButton value="rma">
                                <IonLabel>Pay via RMA</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="eft">
                                <IonLabel>EFT</IonLabel>
                            </IonSegmentButton>
                            {/*
                            <IonSegmentButton value="card">
                                <IonLabel>Pay With Card</IonLabel>
                            </IonSegmentButton>
                            */}
                        </IonSegment>
                    </IonCol>
                </IonRow>
                {error && <p className="ion-text-center"><IonLabel color="danger">{error}</IonLabel></p>}
                <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonCol className="ion-text-right">
                        <IonNote>Wallet Balance: </IonNote>
                    </IonCol>
                    <IonCol className="ion-text-start">
                        <IonBadge color="danger">Nu. {walletBalance}</IonBadge>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonText>Adding fund to your wallet? </IonText>
                </IonRow>
                {paymentMode == 'eft' ?
                    <>
                    <IonRow>
                        <IonNote>Please transfer fund to the following account and submit details here.</IonNote>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Bank Name</IonLabel>
                                <IonText>Bank of Bhutan</IonText>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Account Name</IonLabel>
                                <IonText>EThrom</IonText>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Account Number</IonLabel>
                                <IonText>219127850</IonText> &nbsp; &nbsp;
                                <IonButton onClick={() => copyToClipboard('219127850')}>
                                    <IonIcon icon={copySharp} />&nbsp;
                                    Copy</IonButton>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol> 
                            <IonInput label="Amount" labelPlacement="floating" fill="outline" type="number" placeholder='Enter amount to add'
                                onIonInput={(e) => setStagedAmount(parseInt(e.detail.value || '0'))} required />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonInput label="Reference #/Description" labelPlacement="floating" fill="outline" type="text" 
                                placeholder='Enter reference or description' onIonInput={(e) => setStagedRef(e.detail.value || '')} required />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-right">
                        <IonButton onClick={addFundToWallet} color="drukre" disabled={!authValues.authenticated}>
                            <IonIcon icon={checkmarkSharp} />&nbsp; Submit Now
                        </IonButton>
                        </IonCol>
                    </IonRow>
                    </>   
                :
                <>
                    <IonRow>
                        <IonCol size="8" offset="2"> 
                            <IonInput label="Enter amount here" labelPlacement="floating" fill="outline" type="number" placeholder='Enter amount to add' 
                                onIonChange={(e) => setStagedAmount(parseInt(e.detail.value || '0'))} required />
                        </IonCol>
                        <IonCol className="ion-align-self-center">
                            <RMAPaymentGateway payFunction={addFundToWallet} total={stagedAmount} purchaserData={purchaserData} onSuccess={null} />
                        </IonCol>
                    </IonRow>
                </>
                
                }
            </IonContent>
        </IonPage>
    );
};

const Wallet = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [walletBalance, setWalletBalance] = useState(0);

    const sponsorDismiss = () => {
        setPresentWalletModal()
    }

	const [presentWalletModal, setPresentWalletModal] = useIonModal(WalletModal, {
		walletBalance: walletBalance,
        onDismiss: sponsorDismiss,
		afterEvent: () => { }
    });

    useEffect(() => {
        axiosCall("/api/wallet", 'get', null, true).then((res) => {
            setWalletBalance(res.data.balance);
        })
        .catch((error) => {
            //setError("Couldn't fetch wallet balance.");
            //setIserror(true);
        });
    }, []);

    return (
        <div>
            <IonRow className="ion-align-items-center ion-justify-content-center">
                <IonCol size="6" className="ion-text-right">
                    <IonNote>Wallet Balance: Nu.</IonNote>
                </IonCol>
                <IonCol size="2" className="ion-text-left">
                    <IonBadge color="danger">{walletBalance}</IonBadge>
                </IonCol>
            </IonRow>
            
            <IonRow>
                <IonCol className="ion-text-center">
                        <IonButton color="drukre" onClick={() => presentWalletModal({
                            initialBreakpoint: 0.60,
                            breakpoints: [0, 0.60, 0.75, 1],
                            backdropBreakpoint: 0
                        })}>Add Fund</IonButton>
                </IonCol>
            </IonRow>
            
        </div>
    );
};

export default Wallet;
