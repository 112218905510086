import React, { useState, useEffect } from 'react';
import {
    IonBadge, IonButton, IonIcon,
    IonModal, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonContent,
    IonText,
    IonRow,
    IonCol,
    useIonLoading,
    useIonToast,
    IonNote,
    IonInput,
    useIonModal,
    IonPage,
    IonSegmentButton,
    IonSegment,
    IonTextarea
} from '@ionic/react';
import { notificationsOutline, close, closeOutline, chatbubblesOutline, checkmarkSharp, copyOutline, copySharp } from 'ionicons/icons';

import './Notification.css';

import axiosCall from '../data/axios';

import { getCaptchaToken } from "../Helper";

import AuthContext from "../UserContext";
import { set } from 'date-fns';

const ContactModal = ({ onDismiss, afterEvent }: { onDismiss: any, afterEvent: () => void }) => {

    const { authValues, fetchUser, logout } = React.useContext(AuthContext);

    const [error, setError] = useState('');

    const [spinnerPresent, spinnerDismiss] = useIonLoading();
    const [presentToast] = useIonToast();

    const [name, setName] = useState(authValues?.user?.data?.name || '');
    const [email, setEmail] = useState(authValues?.user?.data?.email || '');
    const [message, setMessage] = useState('');

    const postContact = async (event: any) => {

        if (name === '' || email === '' || message === '') {
            setError('Please fill in all fields.');
            spinnerDismiss();
            return;
        }

        if(!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setError('Please enter a valid email address.');
            spinnerDismiss();
            return;
        }

        spinnerPresent({
            message: 'Sending. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        const headers = {
            'Content-Type': 'application/json'
        }

        var captchaData = {
            token: ''
        };

        await getCaptchaToken('CONTACT').then((token: any) => { // Here wait token generated
            if (token) {
                captchaData.token = token;
            }
        });

        setError('');
        
        axiosCall("/api/contact-us", 'post',
            {                
                name: name, 
                email: email,
                message: message,
                captchaToken: captchaData.token,
                captchaAction: 'CONTACT'
            }, true
        ).then((res: any) => {
            console.log(res);
            if (res.data.status !== 1)
                setError('Inquiry submission unsuccessful! ' + res.message);
            else {
                presentToast({
                    message: 'Inquiry submission successful! We will get back to you soon.',
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
    
                });
                onDismiss();
            }
        })
        .catch((error) => {
            setError("Inquiry submission unsuccessful! Please try again.");
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });
    };

    return (
        <IonPage id="contact-modal">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Contact Us</IonTitle>
                    <div slot="end" className="ion-padding" onClick={() => onDismiss()}><IonIcon size="small" color="drukre" icon={closeOutline} /></div>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonRow>
                    <IonCol className="ion-text-center">
                        
                    </IonCol>
                </IonRow>
                {error && <p className="ion-text-center"><IonLabel color="danger">{error}</IonLabel></p>}
                <IonRow>
                    <IonCol>
                        <IonInput labelPlacement="floating" fill="outline" label="Your Name" value={name} onIonInput={(e) => setName(e.detail.value!)} placeholder="Name" />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonInput labelPlacement="floating" fill="outline" label="Your Email" value={email} onIonInput={(e) => setEmail(e.detail.value!)} placeholder="Email" />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonTextarea label="Message" labelPlacement="floating" fill="outline" placeholder="Enter your message here"
								value={message} onIonInput={(e) => setMessage(e.detail.value || '')}
								counter={true}
								maxlength={1000}
								autoGrow={true}
								counterFormatter={(inputLength, maxLength) => `${maxLength - inputLength} characters remaining`}
                                rows={6}
							></IonTextarea>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonButton onClick={postContact}>Submit</IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

const Contact = () => {

    const cModalDismiss = () => {
        setPresentContactModal()
    }

	const [presentContactModal, setPresentContactModal] = useIonModal(ContactModal, {
        onDismiss: cModalDismiss,
		afterEvent: () => { }
    });

    const contactForm = () => {
        presentContactModal({
            initialBreakpoint: 0.60,
            breakpoints: [0, 0.60, 0.75, 1],
            backdropBreakpoint: 0
        });
    };

    return (
        <span onClick={contactForm}>
            Contact Us
        </span>
    );
};

export default Contact;
