import { ProductStore } from "./ProductStore";
import { CategoryStore } from "./CategoryStore";
import { FeaturedStore } from "./FeaturedStore";

var url = `${process.env.REACT_APP_BASE_URL}`;

export const fetchData = async () => {

    /*
    const json = ["beds.json", "armchairs.json", "coffee_tables.json", "cushions.json", "floor_lamps.json", "office_chairs.json"];

    var products = [];

    json.forEach( async category => {

        const products = await fetchProducts(category);

        let categoryName = category.replace(".json", "");
        categoryName = categoryName.replace("_", " ");
        categoryName = uppercaseWords(categoryName);

        const productCategory = {

            name: categoryName,
            slug: category.replace(".json", ""),
            cover: products[6].image,
            products
        };

        ProductStore.update(s => { s.products = [ ...s.products, productCategory ]; });
    });

    return products;
    */

    var products = [];

    const response = await fetch(url+'/api/categories');

    if (response.status === 429) {
        alert("Please slow down, too many requests. Please wait a few seconds and try again.");
        return;
    }

    const json = await response.json();
        
    CategoryStore.update(s => {s.categories = json.data; s.hasLoaded= true;});

    // Use map to create an array of promises
    const productPromises = json.data.map(async category => {
        const products = await fetchProducts(category.name);

        let categoryName = uppercaseWords(category.description);

        const productCategory = {
            name: categoryName,
            slug: category.name,
            cover: category.name +'.png',
            products
        };

        ProductStore.update(s => { s.products = [ ...s.products, productCategory ]; s.hasLoaded = true; });
    });

    // Use Promise.all to wait for all the promises to resolve
    await Promise.all(productPromises);

    ProductStore.update(s => { s.fullyLoaded = true; });

    await fetchFeatured();

    return products;
}

let rateLimitAlertShown = false;
const fetchProducts = async category => {

    var data = [];

    const response = await fetch(url+'/api/categoryInventory?category='+category);

    if (response.status === 429) {
        if (!rateLimitAlertShown) {
            alert("Please slow down, too many requests. Please wait a few seconds and try again.");
            rateLimitAlertShown = true;
        }
        return;
    }

    const json = await response.json();

    if(json.hasOwnProperty('data'))
        data = json.data.data;

    return data;
}

const uppercaseWords = words => {

    words = words.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

    return words;
}

export const fetchFeatured = async (page=null) => {

    var res = [];

    const json = await fetch(url+'/api/sponsored/'+ (page ? '?page='+page : ''))
    .then(response => response.json())
    .then(async response => {
        FeaturedStore.update(s => {
            s.products = [...s.products, ...response.data]; // Append new products
            s.hasLoaded = true;
            s.pagination = {
                to: response.to,
                total: response.total,
                current_page: response.current_page,
                per_page: response.per_page,
                last_page: response.last_page
            };
        });
        res = response.data;
		//FeaturedStore.update(s => {s.products = response.data; s.hasLoaded= true;});
    });

    return res;
}