import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './UserContext';
import { PostHogProvider } from 'posthog-js/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const options = {
    api_host: 'https://us.i.posthog.com',
};

serviceWorkerRegistration.register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		{process.env.REACT_APP_ENV === 'production' ? (
            <PostHogProvider
                apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_API_KEY}
                options={options}>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </PostHogProvider>
        ) : (
            <AuthProvider>
                <App />
            </AuthProvider>
        )}
	</React.StrictMode>
);

reportWebVitals();
