// src/components/TermsAndConditions.js
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, IonTitle, IonGrid } 
    from '@ionic/react';
import React, { useContext } from 'react';

import { chevronBackOutline,} from "ionicons/icons";

import AuthContext from "../UserContext";

const Instructions = () => {

    const { authValues } = useContext(AuthContext);

    let appName = 'EThrom';
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
			</IonHeader>
            <IonContent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
                <div style={{ padding: '20px', lineHeight: '1.6' }}>
                    <h1>For Sellers</h1>

                    <h4>1. Registration / Login</h4>
                    <p>
                        To be able to sell on {appName} you need to first have an account which can be obtained by following the registration process. Once you have an account, you can login to the app and start selling. 
                    </p>
                    {authValues.authenticated ? (
                        <>
                            <h4>2. Add Products</h4>
                    
                            <p>
                                Go to My Products page. You will see a button with a "+" icon that says ADD NEW ITEM or Add. When you click on it, a modal/popup will appear where you can add product details, images, delivery locations and charges with number of days for delivery/pickup, selling price, etc... 
                                After you save it, the status will go to "Pending Admin Validation". Once the admin validates it, the status will change to "Published" and the product will be available for sale.
                            </p>
                            <p>
                                There are 3 tabs in the My Products page. Current Products, Pending Products, Archived Products. 
                                <li>Current Products: Products that are published and currently available for sale.</li>
                                <li>Pending Products: Products that are waiting for admin validation.</li>
                                <li>Archived Products: Products that are no longer available for sale.</li>
                            </p>
                            <p>
                                You can edit your product details using the EDIT PRODUCT button available which will go through the admin validation process. 
                                There is another button called INSTANT UPDATE which will update the product details without admin validation. Only certain fields are available to update with this process.
                            </p>
                            <h4>3. List As Featured</h4>
                            <p>
                                You can list your product as featured by clicking on the LIST AS FEATURED button in the product list page. 
                                You will have to select the date range and the amount. Once you pay and the admin validation completes, the product will appear in the home page and will be prominently visible for the period you selected.
                            </p>

                            <h4>4. Orders</h4>
                            <p>
                                You can view the orders you received in the ORDERS FOR ME page. You will also recieve email and push notification on your app on your smart phone. You can view the details of the order and mark it as confirmed, complete, etc... 
                                There are 4 tabs on the ORDERS FOR ME page. Open Orders, Confirmed Orders, Closed Orders, Rejected Orders.
                                <li>Open Orders: Orders that are not yet confirmed.</li>
                                <li>Confirmed Orders: Orders that are confirmed but not yet completed.</li>
                                <li>Closed Orders: Orders that are completed.</li>
                                <li>Rejected Orders: Orders that are rejected by you.</li>
                            </p>
                            <h4>5. Payments</h4>
                            <p>
                                Once an order is completed, a 7 days grace period is given to customer to submit any complaints. Once that is passed you can request for payment by clicking on the REQUEST PAYMENT button in the ORDERS FOR ME page.
                                Make sure your account validation is completed before requesting payment. For account validation you need to submit your identification documents and confirm your phone and email. This can be done by clicking on the User Account Validation button
                                that can be found on Account page or Orders For Me page.
                                You will then receive your payment in your bank account.
                            </p>
                        </>
                    ) : <p>Further information to sell are available once you are logged in. Please login to view the information.</p>}

                    <h1>For Buyers</h1>
                    <p>
                        To buy products on {appName}, please click the "Buy Now" button or check out via the cart. You will 
                        have to select the delivery location or the preferred pickup location. Delivery usually involves a charge which you will immediately see when you select the area where you want to be delivered. Please enter proper address for your items to be delivered without issue.
                        You can also see how many days it will take for the product to be delivered or ready for pickup.
                        You can also add a note to the seller if you have any special requests.
                    </p>

                    <h4>1. Verification</h4>
                    <p>
                        Before you are able to place an order you need to confirm your email/phone if you are continueing as a guest or login to your account.
                    </p>

                    <h4>2. Payment</h4>
                    <p>
                        After you pass the verification process or account login, you can navigate to the payment page where you can select the payment method.
                        You can pay using your your bank account via RMA payment gateway, your wallet or a gift card.
                        <li>RMA Payment Gateway: You can pay using your bank account live from our interface.</li>
                        <li>Wallet: You can pay using your {appName} wallet if you have enough balance. You need to be logged in to use your wallet. You can add funds to your wallet using "Add Fund" button on the left menu which can be seen when you are logged in.</li>
                        <li>Gift Card: You can purchase an {appName} gift card and pay using it. Gift cards can be purchased from the {appName} or {appName} giftcard retailers.</li>
                    </p>

                    <h4>3. Your purchases</h4>
                    <p>
                        If you ordered by logging in to your account, you can view your purchases and their statuses in the MY PURCHASES page.
                        From there you can also contact the seller if you have any questions or issues.
                        
                    </p>
                    <p>
                        If you ordered as a guest, you can track your order using the order number on the Track Order page.
                    </p>
                    <p>
                        Once the order is delivered or picked, if there is any issue with the order you will have opportunity to submit a complaint within 7 days.
                        Within this period {appName} will investigate the issue and take necessary actions. 
                        If nothing is submitted within this period, the order will be considered as completed and the payment will be released to the seller.
                    </p>
                </div>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonContent>
        </IonPage>
    );
};

export default Instructions;
